<script lang="ts" setup>
import { parseMarkdown } from "@nuxtjs/mdc/runtime";
import ProseCode from "@/components/Dc/Markdown/Prose/Code.vue";
import ProseA from "@/components/Dc/Markdown/Prose/A.vue";

const props = defineProps<{
  md: string;
  size?: "sm" | "md";
}>();

const parsed = computedAsync(async () => props.md ? await parseMarkdown(props.md) : "");
</script>

<template>
  <MDCRenderer
    v-if="parsed"
    class="prose-primary prose max-w-none dark:prose-invert dark:prose-pre:!bg-gray-800/75"
    :class="[size === 'sm' ? 'prose-sm' : 'prose-sm lg:prose-base']"
    :body="parsed.body"
    :data="parsed.data"
    :components="{
      pre: ProseCode,
      a: ProseA,
    }"
  />
  <span v-else />
</template>
